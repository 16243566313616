import { addEventDelegate } from "@jeffcarbine/premmio/public/modules/eventDelegate/eventDelegate.js";
import { xhrForm } from "@jeffcarbine/premmio/public/modules/xhr/xhr.js";

const addEmailSubscription = (form) => {
  xhrForm({
    form,
    successMessage:
      "Welcome to the Honk Legion! You have been subscribed to our newsletter.",
  });
};

addEventDelegate(
  "submit",
  "form#emailSubscription",
  addEmailSubscription,
  true
);
